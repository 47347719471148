.portfolio__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem ;
}

.portfolio_navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center
}

.portfolio__navbar-links_name {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    padding: 1rem 1rem;

    background: linear-gradient(89.98deg, #728DA8 -1.29%, #DAD3B4 86.97%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.portfolio__navbar-links_container {
    display: flex;
    flex-direction: row;
}

/* only target ps */
.portfolio__navbar-links_container p,
.portfolio__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

.portfolio__navbar-menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}

.portfolio__navbar-menu svg {
  cursor: pointer;
}

.portfolio__navbar-menu_container { 
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  text-align: end;
  background: var(--color-footer);
  padding: 2rem;
  position: absolute;
  top: 40px;
  right: 0;
  margin-top: 1rem;
  min-width: 210px;
  border-radius: 5px;
  box-shadow: 0 0 5 rgba(0,0,0,0.2);
}

.portfolio__navbar-menu_container p {
  margin: 1rem 0;
}

/* queries for mobile */
@media screen and (max-width: 1050px) {
  .portfolio__navbar-links_container {
    display: none;
  }

  .portfolio__navbar-menu {
    display: flex;
  }
}

@media screen and (max-width: 700px) {
  .portfolio__navbar {
    padding: 2rem 4rem;
  }
}

@media screen and (max-width: 550px) {
  .portfolio__navbar {
    padding: 2rem;
  }
  .portfolio__navbar-menu_container {
    top: 20px;
  }
}
