.portfolio__education {
  display: flex;
  flex-direction: row;
}

.portfolio__education-image {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-right: 2rem;
}

.portfolio__education-image img {
  width: 75%;
  height: auto;
}

.portfolio__education-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.portfolio__education-content h4 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;    
  color: #DAD3B4;
}

.portfolio__education-content h1 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;

  font-family: var(--font-family);
  font-weight: 800;
  font-size: 34px;
  line-height: 45px;
}

.portfolio__education-content p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #DAD3B4;
}

@media screen and (max-width: 950px) {
  .portfolio__education {
    flex-direction: column;
  }
  .portfolio__education-image {
    margin: 1rem 0;
  }
  .portfolio__education-content {
    margin-top: 2rem;
  }
  .portfolio__education-image img {
    width: 100%;
    height: 100%;
  }
}