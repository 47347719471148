.portfolio__footer {
  background-color: #38455A;
}

.portfolio__footer-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    
    margin: 1remz;
}

.portfolio__footer-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 62px;
  line-height: 75px;
  text-align: center;
  letter-spacing: -0.04em;
  margin: 1rem;
}

.portfolio__footer-content img {
  height: auto;
  width: 120px;
  margin: 1rem;
}

.portfolio__footer-content button {
  margin: 1rem;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;

  color: #728DA8;
  border: 2px solid black;
  background-color:#38455A;
  border-color: #728DA8;
}

@media screen and (max-width: 850px) {
  .portfolio__footer-content h1 {
    font-size: 40px;
  }
}