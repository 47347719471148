.portfolio__hi3tech {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.portfolio__hi3tech-heading {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column; 
  text-align: left;
  margin-right: 5rem;

  display: flex;
  justify-content: space-between;
  padding: 2rem;
  background: var(--color-footer);

  /* ff 3.6+ */
  background:-moz-radial-gradient(circle at 30% -100%, #728DA8 5%, #728DA8 20%, #292C41 80%);
  /* safari 5.1+,chrome 10+ */
  background:-webkit-radial-gradient(circle at 30% -100%, #728DA8 5%, #728DA8 20%, #292C41 80%);
  /* opera 11.10+ */
  background:-o-radial-gradient(circle at 30% -100%, #728DA8 5%, #728DA8 20%, #292C41 80%);
  /* ie 10+ */
  background:-ms-radial-gradient(circle at 30% -100%, #728DA8 5%, #728DA8 20%, #292C41 80%);
  /* global 92%+ browsers support */
  background:radial-gradient(circle at 30% -100%, #728DA8 5%, #728DA8 20%, #292C41 80%);

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.portfolio__hi3tech-heading img {
  max-width: 250px;
  height: auto;
  opacity: 0.25;
}

.portfolio__hi3tech-heading h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 34px;
  line-height: 45px;
}

.portfolio__hi3tech-container {
  flex: 1.5;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

@media screen and (max-width: 990px) {
  .portfolio__hi3tech {
    flex-direction: column;
  }
  .portfolio__hi3tech-heading {
    background-image: none;
    padding: 0rem;
  }
  .portfolio__hi3tech-heading img {
    display: none;
  }
}

@media screen and (max-width: 550px) {
  .portfolio__hi3tech-heading h1 {
    font-size: 28px;
    line-height: 38px;
  }
  .portfolio__hi3tech-heading img {
    display: none;
  }
  .portfolio__hi3tech {
    margin-top: 1rem;
  }
}