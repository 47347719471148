.portfolio__header {
    display: flex;
}

.portfolio__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 5rem;
}

.portfolio__header-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 62px;
  line-height: 75px;
}

.portfolio__header-content p {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: var(--color-text);
  margin-top: 1.5rem;
}

.portfolio__header-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.portfolio__header-image-cocky {
  mix-blend-mode: normal;
  filter: blur(2px);
}

.portfolio__header-image img {
  width: 100%;
  height: 100%;
  border-radius: 166px; 
}

.portfolio__header-links {
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
}

.portfolio__header-links img {
  height: auto;
  width: 90px;
  margin-right: 0.5rem;
}


@media screen and (max-width: 1050px) {
  .portfolio__header {
    flex-direction: column;
  }
  .portfolio__header-content {
    margin: 0 0 3rem;
  }
}

@media screen and (max-width: 490px) {
  .portfolio__header h1 {
    font-size: 36px;
    line-height: 48px;
  }
  .portfolio__header p {
    font-size: 14px;
    line-height: 24px;
  }
}