.portfolio__youth4youth-heading {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 40px;
    line-height: 55px;
}
#youth4youth {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.portfolio__youth4youth-heading-bar {
    width: 100px;
    height: 8px;
    background: var(--gradient-bar);
    box-shadow: 10px 10px 10px rgba(0,0,0,0.25);
    margin-bottom: 0.25rem;
}

.portfolio__youth4youth-container {
    display: block;  
    justify-content: center;
    flex: 1;
}

.header_container {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.header_container img {
    width: 15%;
}

.MockContainer {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.phoneMockImage {
    width: 30rem;   
}

.lower-container {
    display: flex;
    gap: .5rem;
}
.portfolio__youth4youth-container p {
    color: #DAD3B4;
    font-family: var(--font-family);
    line-height: 30px;
    position: relative;
    top: 10%;
}

@media screen and (max-width: 1050px) {
    .header_container {
        margin-top: 1rem;  
    }
    .phoneMockImage {
        width: 70%;
        padding: 1rem;
    }
    .header_container img {
        display: none;
    }
    .lower-container {
        flex-direction: column-reverse;
    }
    #youth4youth {
        gap: 2rem;
        display: flex;
        flex-direction: column;
    }
}