.chatbot_section {
    display: flex;
    margin-left: 6rem;
    margin-right: 6rem;
}
.chatbot_explanation {
    flex: 6;
    display: flex;
 
    flex-direction: column;
    text-align: left;
    margin-left: 5rem;
    font-family: var(--font-family);
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 30% -100%, #728DA8 5%, #728DA8 20%, #292C41 80%);
    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 30% -100%, #728DA8 5%, #728DA8 20%, #292C41 80%);
    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 30% -100%, #728DA8 5%, #728DA8 20%, #292C41 80%);
    /* ie 10+ */
    background:-ms-radial-gradient(circle at 30% -100%, #728DA8 5%, #728DA8 20%, #292C41 80%);
    /* global 92%+ browsers support */
    background:radial-gradient(circle at 30% -100%, #728DA8 5%, #728DA8 20%, #292C41 80%);
  
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.chatbot_explanation h1 {
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;
}
.chatbot_explanation p {
    color: #DAD3B4; 
}
.chatcontainer {
    height: 30rem;
    max-height: 30rem;
    min-height: 30rem;
    display: flex;
    flex: 5;
    flex-direction: column;
    background-color: var(--color-blog);
    border-radius: 15px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    font-family: var(--font-family);
    font-style: normal;
    padding: .5rem;

    overflow: auto;
    scrollbar-color: var(--color-primary) var(--color-blog);
}
.messages_box {
    display: flex;
    height: 30rem;
    flex-direction: column;
    overflow-y: auto;
    padding: 1rem;
}
.inputbox_container input{
    width: 80%;
    padding: 0.5rem;
    border: none;
    border-radius: 5px;
    margin: 0 0.5rem;
    font-family: var(--font-family);
    font-style: normal;
    font-size: medium;
    outline: none;
}
.inputbox_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    border-radius: 10px;
    background-color: var(--color-bg);
}
.inputbox_container button {
    width: 15%;
    text-align: center;
    padding: 0.5rem;
    border: 1.5px solid var(--color-text);
    color: var(--color-text);
    border-radius: 5px; 
    font-family: var(--font-family);
    /* Transition to hover */
    -moz-transition: all .15s ease-in;
    -o-transition: all .15s ease-in;
    -webkit-transition: all .15s ease-in;
    transition: all .15s ease-in;
    font-weight: 500;
}
.inputbox_container button:hover {
    color: white;
    border: 1.5px solid white;
}
/* Messages styling */
.message__assistant {
    padding: 0.5rem;
    border-radius: 5px;
    max-width: 70%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 10px 10px 10px 0px;
    color: white;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        /* ff 3.6+ */
        background:-moz-radial-gradient(circle at 30% -100%, #728DA8 5%, #728DA8 20%, #292C41 80%);
        /* safari 5.1+,chrome 10+ */
        background:-webkit-radial-gradient(circle at 30% -100%, #728DA8 5%, #728DA8 20%, #292C41 80%);
        /* opera 11.10+ */
        background:-o-radial-gradient(circle at 30% -100%, #728DA8 5%, #728DA8 20%, #292C41 80%);
        /* ie 10+ */
        background:-ms-radial-gradient(circle at 30% -100%, #728DA8 5%, #728DA8 20%, #292C41 80%);
        /* global 92%+ browsers support */
        background:radial-gradient(circle at 100%, #728DA8 , #292C41 100%);
      
}
.message__user {
    color: white;
    padding: 0.5rem;
    border-radius: 10px 10px 0 10px;
    text-align: end;
    max-width: 70%;
    overflow-wrap: break-word;
    font-weight: bold;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    align-self: flex-end;
     /* ff 3.6+ */
     background:-moz-radial-gradient(circle at 30% -100%, #728DA8 5%, #728DA8 20%, #292C41 80%);
     /* safari 5.1+,chrome 10+ */
     background:-webkit-radial-gradient(circle at 30% -100%, #728DA8 5%, #728DA8 20%, #292C41 80%);
     /* opera 11.10+ */
     background:-o-radial-gradient(circle at 30% -100%, #728DA8 5%, #728DA8 20%, #292C41 80%);
     /* ie 10+ */
     background:-ms-radial-gradient(circle at 30% -100%, #728DA8 5%, #728DA8 20%, #292C41 80%);
     /* global 92%+ browsers support */
     background:radial-gradient(circle at 100%, #728DA8 , #292C41 100%);
}

@media screen and (max-width: 1050px) {
    .chatbot_section {
      flex-direction: column;
      margin-left: 2rem;
      margin-right: 2rem;
    }
    .chatbot_explanation {
        margin-left: 0;
        margin-top: 2rem;
        margin-bottom: -5rem;
    }
    .chatbot_explanation h1{
      margin-bottom: 1rem;
    }
}
  