.portfolio__photography-heading {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 40px;
    line-height: 55px;
    text-align: inherit;
}
.portfolio__photography-heading-bar {
    width: 10%;
    height: 6px;
    background: var(--gradient-bar);
    box-shadow: 10px 10px 10px rgba(0,0,0,0.25);
    margin-bottom: 0.25rem;
}
.portfolio__photography-container-img {
    box-shadow: 0.5rem 0.5rem 0.25rem;
    transition: 1s ease;
    max-width: 225px;
    height: auto;
    border-radius: 10px;
    border: 5px solid white;
}
.portfolio__photography-container p {
    color: #DAD3B4;
    font-family: var(--font-family);

    line-height: 30px;
}
.portfolio__photography-container-words {
    padding: 1rem;
     /* ff 3.6+ */
     background:-moz-radial-gradient(circle at 30% -100%, #728DA8 5%, #728DA8 20%, #292C41 80%);
     /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 30% -100%, #728DA8 5%, #728DA8 20%, #292C41 80%);
    /* opera 11.10+ */
     background:-o-radial-gradient(circle at 30% -100%, #728DA8 5%, #728DA8 20%, #292C41 80%);
    /* ie 10+ */
     background:-ms-radial-gradient(circle at 30% -100%, #728DA8 5%, #728DA8 20%, #292C41 80%);
      /* global 92%+ browsers support */
    background:radial-gradient(circle at 30% -100%, #728DA8 5%, #728DA8 20%, #292C41 80%);

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.portfolio__photography-container-images {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.portfolio__photography-container-images img {
    width: 20%;
    height: 30rem;
    object-fit: cover;
    image-resolution: 1ddpx;
    margin: .25rem;
}
.UP {
    padding-bottom: 1rem !important;
}
.DOWN {
    padding-top: 1rem !important;
}

@media screen and (max-width: 650px) {

    .DA-logo {
        max-width: 100px;
        border: none !important;
    }
    .child:hover {
        z-index: 1;
        -webkit-transform: scale(2,2);
        -ms-transform: scale(2,2);
        transform: scale(2,2);
        transition: 1.5s ease;
    }
}